<template>
<v-navigation-drawer :permanent="true" :mini-variant="!drawer" app floating width="190" color="primary" dark>
  <v-container>
    <v-col>
      <v-row>
        <v-spacer></v-spacer>
        <v-icon @click="setDrawer" small>mdi-menu</v-icon>
        <v-spacer v-if="!drawer"></v-spacer>
      </v-row>
    </v-col>
  </v-container>
  <v-divider></v-divider>
  <LeftBarMenu :item="item" v-for="(item, i) in items" :key="i" />
</v-navigation-drawer>
</template>

<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import {
  Routes
} from "@/constants/routes";
export default {
  name: "LeftBar",
  components: {
    LeftBarMenu: () => import("@/components/common/LeftBarMenu"),
  },
  computed: {
    ...mapGetters("app", ["drawer"]),
  },
  data() {
    return {
      items: [{
          title: "员工管理",
          icon: "mdi-account",
          children: [{
              name: "在职员工",
              link: Routes.ADMIN_EMPLOYEE_PAGE,
              pageName: 'employeePage'
            },
            {
              name: "离职员工",
              link: Routes.ADMIN_RESIGN_PAGE,
              pageName: 'resignPage'
            },
            // {
            //   name: "外部成员管理",
            //   link: Routes.ADMIN_EXTERNAL_PAGE,
            // },
            {
              name: "群组管理",
              link: Routes.ADMIN_GROUP_PAGE,
              pageName: 'groupPage'
            },
            {
              name: "职务",
              link: Routes.ADMIN_DUTY_PAGE,
              pageName: 'dutyPage'
            },
          ],
        },
        {
          title: "部门管理",
          link: Routes.ADMIN_DEPARTMENT_PAGE,
          pageName: "departmentPage",
          icon: "mdi-file-document"
        },
        {
          title: "管理员授权",
          icon: "mdi-crown",
          link: Routes.ADMIN_MANAGE_PAGE,
          pageName: 'adminManagePage'
        },
        {
          title: "企业设置",
          icon: "mdi-monitor",
          children: [
            {
              name: '企业信息',
              link: Routes.ADMIN_COMPANYINFO_PAGE,
              pageName: 'companyInfoPage'
            },
            // {
            //   name: '邮件设置',
            //   link: Routes.ADMIN_EMAIL_PAGE,
            //   pageName: 'emailPage'
            // },
          ]
        },
        // {
        //   title: "开启高级功能",
        //   icon: " mdi-poll-box",
        //   link: Routes.ADVANCED_FUNC_PAGE,
        //   pageName: 'advancedFuncPage'
        // },
      ],
      right: null,
    };
  },
  methods: {
    ...mapActions("app", ["setDrawer"]),
  },
};
</script>

<style></style>
